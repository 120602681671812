<template>
    <div>
        <!-- 海运订单 -->
        <OceanShipping_Component></OceanShipping_Component>
    </div>
</template>

<script>
import OceanShipping_Component from '../../components/PersonalCenter/OceanShipping_Component.vue'
export default {
    name: "OceanShipping",
    components: { OceanShipping_Component }

}
</script>

<style>

</style>